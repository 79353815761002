import {Dispatch, SetStateAction, useCallback, useEffect, useRef} from "react";
import mapboxgl from 'mapbox-gl';
import {useDispatch} from "react-redux";
import {clearedSelectedMapFeatures, setSelectedMapFeatures} from "../../redux/showInfo/showInfoReducer";
import {FeatureState, MapboxGeoJSONFeatureWithProperties} from "../../redux/map/types";
import type {MapboxMap} from "react-map-gl/src/types";
import {removeFeatureState, setFeatureState} from "../../utils/mapUtils";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectRedirectOnFeatureSelection, selectRequireToHighlightFeatures} from "../../redux/selectors/selectors";
import {SelectedFeatureProperties} from "../../redux/showInfo/types";


const useMapFeatureHighlighting = (setDragPan: Dispatch<SetStateAction<boolean>>) => {
    const highlightedFeaturesProperties = useRef<SelectedFeatureProperties[]>([]);
    const featuresRequiredToHighlight = useTypedSelector(selectRequireToHighlightFeatures);
    const redirectOnFeatureSelection = useTypedSelector(selectRedirectOnFeatureSelection);
    const mapRef = useRef<MapboxMap>();
    const canvasRef = useRef<HTMLElement>();
    const dispatch = useDispatch();

    useEffect(() => {
        const map = mapRef.current;

        if (map?.loaded()) {
            for (const {featureStateId, layerId} of highlightedFeaturesProperties.current) {
                removeFeatureState({mapRef: map, featureStateId, layerId, stateProperty: FeatureState.HIGHLIGHTED});
            }
            highlightedFeaturesProperties.current = [];

            featuresRequiredToHighlight.map(featureProperties => {
                const {featureStateId, layerId} = featureProperties;
                highlightedFeaturesProperties.current.push(featureProperties);
                setFeatureState({
                    mapRef: map,
                    featureStateId,
                    layerId,
                    stateProperty: FeatureState.HIGHLIGHTED,
                    stateValue: true
                });
            })
        }
    }, [featuresRequiredToHighlight]);

    function onKeyUp(e) {
        if (e.keyCode === 27 && mapRef.current) {
            for (const {featureStateId, layerId} of highlightedFeaturesProperties.current) {
                removeFeatureState({
                    mapRef: mapRef.current,
                    featureStateId,
                    layerId,
                    stateProperty: FeatureState.HIGHLIGHTED
                });
            }
            removeAllFeaturesState(mapRef.current, FeatureState.HOVERED);

            dispatch(clearedSelectedMapFeatures());
        }
    }

    function addOrRemoveFeaturesState(mapRef: MapboxMap, features: MapboxGeoJSONFeatureWithProperties[], stateProperty: string) {
        for (const {properties: {id, featureStateId, entityName}, source, state} of features) {
            if (state[stateProperty]) {
                highlightedFeaturesProperties.current = highlightedFeaturesProperties.current.filter(properties => properties.id !== id)
                removeFeatureState({
                    mapRef: mapRef,
                    featureStateId: featureStateId.toString(),
                    layerId: source,
                    stateProperty
                });
            } else {
                const featureStateIdStr = featureStateId.toString();
                highlightedFeaturesProperties.current = [
                    ...highlightedFeaturesProperties.current,
                    {
                        id: id as string,
                        featureStateId: featureStateIdStr,
                        layerId: source,
                        entityName
                    }
                ]
                setFeatureState({
                    mapRef,
                    featureStateId: featureStateIdStr,
                    layerId: source,
                    stateProperty,
                    stateValue: true
                });
            }
        }

        // dispatch(requireToHighlightFeatures({featureProperties: featurePropertiesToHighlight}));
        dispatch(setSelectedMapFeatures(highlightedFeaturesProperties.current));
    }

    function removeAllFeaturesState(mapRef: MapboxMap, stateProperty: string) {
        for (const {featureStateId, layerId} of highlightedFeaturesProperties.current) {
            removeFeatureState({mapRef: mapRef, featureStateId: featureStateId.toString(), layerId, stateProperty});
        }

        highlightedFeaturesProperties.current = [];
    }

    const featureClickListener = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
        const {features, point, lngLat, originalEvent} = event;
        const {shiftKey} = originalEvent;

        // const uniqueFeatures = getUniqueFeatures(features ?? []) as MapboxGeoJSONFeatureWithProperties[];

        if (mapRef.current) {
            if ((!shiftKey && features?.length && redirectOnFeatureSelection) || (!features?.length && redirectOnFeatureSelection)) {
                removeAllFeaturesState(mapRef.current, FeatureState.HIGHLIGHTED);
                // removeAllFeaturesState(mapRef.current, FeatureState.HOVERED);
            }
            addOrRemoveFeaturesState(mapRef.current, features?.length ? features as MapboxGeoJSONFeatureWithProperties[] : [], FeatureState.HIGHLIGHTED);
        }
    }, [redirectOnFeatureSelection])

    const addFeatureSelectionEventListeners = (map: MapboxMap) => {
        mapRef.current = map;
        canvasRef.current = map.getCanvasContainer();
        canvasRef.current.addEventListener('mousedown', mouseDown, true);
        document.addEventListener('keyup', onKeyUp);
    }

    let start;
    let current;
    let box;

    function mousePos(e) {
        if (canvasRef.current) {
            const rect = canvasRef.current.getBoundingClientRect();
            return new mapboxgl.Point(
                e.clientX - rect.left - canvasRef.current.clientLeft,
                e.clientY - rect.top - canvasRef.current.clientTop
            );
        }
    }

    function mouseDown(e) {
        if (!(e.shiftKey && e.button === 0)) {
            return;
        }

        setDragPan(false);

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        start = mousePos(e);
    }

    function onMouseMove(e) {
        current = mousePos(e);

        if (!box && canvasRef.current) {
            box = document.createElement('div');
            box.classList.add('boxdraw');
            canvasRef.current.appendChild(box);
        }

        const minX = Math.min(start.x, current.x),
            maxX = Math.max(start.x, current.x),
            minY = Math.min(start.y, current.y),
            maxY = Math.max(start.y, current.y);

        const pos = `translate(${minX}px, ${minY}px)`;
        box.style.transform = pos;
        box.style.width = maxX - minX + 'px';
        box.style.height = maxY - minY + 'px';
    }

    function onMouseUp(e) {
        finish([start, mousePos(e)]);
    }

    function finish(bbox) {
        if (bbox && mapRef.current) {
            const features = mapRef.current.queryRenderedFeatures(bbox,
                {
                    filter: ['!=', ['string', ['get', 'layerId']], 'composite']
                }
            );

            if (features.length >= 1000) {
                return window.alert('Select a smaller number of features');
            }

            addOrRemoveFeaturesState(mapRef.current, features as any, FeatureState.HIGHLIGHTED);
            //TODO maybe a temporary, but required for group selection
            dispatch(setSelectedMapFeatures(highlightedFeaturesProperties.current));
        }

        setDragPan(true);

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        if (box) {
            box.parentNode.removeChild(box);
            box = null;
        }
    }

    const removeFeatureSelectionEventListeners = useCallback(() => {
        document.removeEventListener('keyup', onKeyUp);
    }, [onKeyUp])

    return {addFeatureSelectionEventListeners, featureClickListener, removeFeatureSelectionEventListeners};
}

export default useMapFeatureHighlighting;