import React from 'react';
import {DeleteIcon} from "../icons/icons/DeleteIcon";
import './entities-list-row.scss';
import {IconButton} from "../buttons/IconButton/IconButton";
import LinkWrapper from "../LinkWrapper/LinkWrapper";
import classNames from "classnames";
import {BaseEntity, BaseEntityNameLess} from "../../api/entities/BaseEntity";


export type EntitiesListRowProps<T extends BaseEntity | BaseEntityNameLess> = {
    entity: T;
    deleteFn: ({entity}: { entity: T }) => void;
    form?: any; // fix type
    selected?: boolean;
    getUrlFunc?: (entity: T) => string;
    onRowClick: (entity: T) => Promise<void>;
    hasLink?: boolean;
    actions?: React.ReactNode;
}

export const EntitiesListRow = React.memo(React.forwardRef(<T extends BaseEntityNameLess, >({
                                                                                                entity,
                                                                                                deleteFn,
                                                                                                form: Form,
                                                                                                selected,
                                                                                                getUrlFunc,
                                                                                                onRowClick,
                                                                                                hasLink = true,
                                                                                                actions,
                                                                                            }: EntitiesListRowProps<T>, ref: React.ForwardedRef<HTMLDivElement>) => {
    const classes = classNames(
        'entities-list-row',
        selected && 'entities-list-row_selected'
    )

    const onDeleteHandler = async (event, entity: T) => {
        deleteFn?.({entity});
    }

    return (
        <div
            className={classes}
            ref={ref}
            data-testid={`entitiesListRow_${entity.id}`}
        >
            <div
                className={`entities-list-row__link-wrapper`}
                onClick={() => onRowClick(entity)}
            >
                <LinkWrapper
                    to={getUrlFunc?.(entity) ?? `./${entity.id}/`}
                    hasLink={hasLink}
                    className={`entities-list-row__link`}
                >
                    <div className={`entities-list-row__fields`}>
                        {Form ?
                            <Form entity={entity}/>
                            : entity.id
                        }
                    </div>
                </LinkWrapper>
            </div>
            <div className="entities-list-row__actions">
                {actions}
                <IconButton
                    className="entities-list-row__delete-btn"
                    icon={DeleteIcon}
                    width={20}
                    height={20}
                    onClick={(event) => onDeleteHandler(event, entity)}
                    borderless
                    testId="entitiesListRowDeleteBtn"
                />
            </div>
        </div>
    )
}))