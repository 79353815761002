import restClient from "./RestClient";
import {ChartData} from "chart.js";
import {DashboardEmbeddingResultEnvelopeEntity} from "./entities/local/replancity_DashboardEmbeddingResultEnvelope";
import {ClickResponse} from "./entities/replancity_ClickResponse";


export type ModeCountResponse = {
  count: number;
  mode: string;
}

export const dashboardApi = {

  getModalSplit: function ({
                             projectId,
                             modal,
                             abortSignal
                           }: { projectId: string, modal: string, abortSignal: AbortSignal }): Promise<ChartData> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/modalSplit?source=${modal}`,
      fetchOptions: {signal: abortSignal}
    })
  },

  getModeCount: function ({
                            projectId,
                            mode,
                            abortSignal
                          }: {
    projectId: string,
    mode: string,
    abortSignal: AbortSignal
  }): Promise<ModeCountResponse> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/modeCount?mode=${mode}`,
      fetchOptions: {signal: abortSignal}
    });
  },

  getModeStats: function ({
                            projectId,
                            mode,
                            abortSignal
                          }: { projectId: string, mode: string, abortSignal: AbortSignal }): Promise<ChartData> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/modeStats?mode=${mode}`,
      fetchOptions: {signal: abortSignal}
    });
  },

  getDrtStatistics: function ({
                                projectId,
                                abortSignal
                              }: { projectId: string, abortSignal: AbortSignal }): Promise<any> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/get_drt_statistics`,
      fetchOptions: {signal: abortSignal}
    });
  },

  getCarSharingStatistics: function ({
                                       projectId,
                                       abortSignal
                                     }: { projectId: string, abortSignal: AbortSignal }): Promise<any> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/get_carsharing_statistics`,
      fetchOptions: {signal: abortSignal}
    });
  },

  getAvailableModes: function ({
                                 projectId,
                                 abortSignal
                               }: { projectId: string, abortSignal: AbortSignal }): Promise<any> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/mode_availability/`,
      fetchOptions: {signal: abortSignal}
    });
  },

  getProjectDashboardEmbedded: function ({
                                           projectId,
                                           abortSignal
                                         }: { projectId: string, abortSignal: AbortSignal }): Promise<any> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_LookerAuthService/getProjectDashboardEmbedded?projectUUID=${projectId}`,
      fetchOptions: {signal: abortSignal}
    });
  },

    getMainDashboard: function ({
                                    projectId,
                                    abortSignal
                                }: { projectId: string, abortSignal: AbortSignal }): Promise<any> {
        return restClient.fetch({
            method: "GET",
            path: `services/replancity_LookerAuthService/getMainDashboard`,
            data: {
                projectUUID: projectId
            },
            fetchOptions: {signal: abortSignal}
        })
    },

  getDashboardTemplates: function ({
                                     projectId,
                                     abortSignal
                                   }: { projectId: string, abortSignal: AbortSignal }): Promise<any> {
    return restClient.fetch({
      method: "GET",
      path: `projects/${projectId}/dashboard_templates/`,
      fetchOptions: {signal: abortSignal}
    });
  },

  getEmbeddedDashboard: function ({
                            projectId,
                            dashboardId,
                            runId,
                            abortSignal
                          }: {
      projectId: string;
      dashboardId: string;
      runId: string;
      abortSignal: AbortSignal;
  }): Promise<DashboardEmbeddingResultEnvelopeEntity> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_LookerAuthService/getEmbeddedDashboard`,
        data: {
            projectUUID: projectId,
            dashboardUUID: dashboardId,
            runUUID: runId
        },
      fetchOptions: {signal: abortSignal}
    });
  },

  getClickedLinkDashboards: function (
    params: {
        clickRequest: {
            projectId: string;
            runId: string;
            elements: {
                entityName: string;
                featureId: string;
            }[];
        }
    },
  ): Promise<ClickResponse> {
    return restClient.fetch({
      method: "POST",
      path: `services/replancity_LookerAuthService/getClickDashboard`,
      data: params
    });
  },

}