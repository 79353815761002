import React from "react";
import {Layer} from "react-map-gl"
import {useTypedSelector} from "../../../redux/Hooks/storeSelectors";
import {selectMapboxLayerById, selectMapboxLayersById} from "../../../redux/selectors/selectors";
import {NamedLayer} from "../../../redux/map/types";
import {useMapFiltersContext} from "../../../context/mapFiltersContext";


type LayerWrapperProps = {
  layerId: string;
}

const LayerWrapper = ({layerId}: LayerWrapperProps) => {
  const layer: NamedLayer = useTypedSelector(state => selectMapboxLayerById(state, layerId));
  const {mapFilter} = useMapFiltersContext();

  return <Layer
      {...(mapFilter.length ? {filter: mapFilter} : {})}
      {...layer}
  />
}

const MemoLayer = React.memo(LayerWrapper)

const MapboxGlLayers = () => {
  const layersById = useTypedSelector(selectMapboxLayersById);

  return <>
    {
      Object.entries(layersById).map(([id]) =>
        <MemoLayer key={id} layerId={id}/>
      )
    }
  </>
}

export default MapboxGlLayers