import restClient from "./RestClient";
import {RoadInfrastructureRouteEntity} from "./entities/replancity_RoadInfrastructureRoute";


export const roadManagementApi = {
  groupUpdate: function (
    data: any,
    abortSignal: AbortSignal
  ):
    Promise<any[]> {
    return restClient.fetch({
      method: "POST",
      path: `services/replancity_RoadInfrastructureService/groupSaveAndUpdateV2`,
      data,
      fetchOptions: {signal: abortSignal}
    });
  },

  getModes: function (
    data: {presetId: string},
    abortSignal: AbortSignal
  ):
    Promise<string[]> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_RoadInfrastructureService/listAllModes`,
      data,
      fetchOptions: {signal: abortSignal}
    });
  },

  calculateAndGetRouteGeometry: function (
      data: { presetId: string; fromLon: number; fromLat: number; toLon: number; toLat: number; mode: string; },
      abortSignal: AbortSignal
  ):
      Promise<RoadInfrastructureRouteEntity> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_RoadInfrastructureService/calculateRoute`,
      data,
      fetchOptions: {signal: abortSignal}
    });
  },

  listAllHighways: function (
      data: { presetId: string;},
      abortSignal: AbortSignal
  ):
      Promise<{id: string; name: string;}[]> {
    return restClient.fetch({
      method: "GET",
      path: `services/replancity_RoadInfrastructureService/listAllHighways`,
      data,
      fetchOptions: {signal: abortSignal}
    });
  },

}