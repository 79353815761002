import React, {useState} from "react";
import Modal from "../../components/Modal/Modal";
import {useTranslation} from "react-i18next";
import {useProjectsLoader} from "../../hooks/projects/useProjectsLoader";
import './delete-project-modal.scss';
import {LoadingBackdrop} from "../../components/LoadingBackdrop/LoadingBackdrop";
import {isErrorResponse} from "../../utils/utils";
import {deleteProjectStorageMapViewState} from "../../utils/projectUtils";
import {Button} from "../../components";
import {useToastContext} from "../../context/toastContext";


const DeleteProjectModal = ({projectId, setVisible, setSelectedProjectId}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {loadData: loadProjects, deleteProject} = useProjectsLoader();
  const {t} = useTranslation();
  const {addToast} = useToastContext();

  const onDeleteHandler = async () => {
    setLoading(true);

    const resp = await deleteProject(projectId);
    if (isErrorResponse(resp)) {
      const {error, details} = resp as any;
      addToast(`${error}: ${details}`, 'error', false);
    } else {
      deleteProjectStorageMapViewState(projectId);
      await loadProjects();
    }
    setSelectedProjectId('');
    setVisible();

    setLoading(false);
  }

  const cancelHandler = () => {
    setSelectedProjectId('');
    setVisible();
  }

  return (
    <LoadingBackdrop isVisible={loading} transparent>
      <Modal title={t("delete-project-popup.title")} setVisible={setVisible}>
        <div className="delete-project-modal">
          <div className="transparent-button-block">
            <Button
              onClick={cancelHandler}
              text={t("delete-project-popup.cancel")}
              colorType="transparent"
            />
          </div>
          <div className="disabled-button-block">
            <Button
              onClick={onDeleteHandler}
              type="submit"
              text={t("delete-project-popup.delete")}
              colorType="dark"
              testId={`delete-project-popup_btn_${projectId}`}
            />
          </div>
        </div>
      </Modal>
    </LoadingBackdrop>
  )
}

export default DeleteProjectModal;