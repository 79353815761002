import React, {useState} from "react";
import {EntitiesListRow, EntitiesListRowProps} from "../EntitiesListRow";
import './entities-list.scss';
import {BaseEntityNameLess} from "../../../api/entities/BaseEntity";
import {LoadingBackdrop} from "../../LoadingBackdrop/LoadingBackdrop";
import DeleteEntityModal from "../DeleteEntityModal/DeleteEntityModal";
import {ActionsProps} from "../EntitiesLoader/EntitiesLoader";


type Props<T extends BaseEntityNameLess> = {
    loading?: boolean;
    entities: T[];
    selectedId?: string;
    actions?: React.ComponentType<ActionsProps>;
    loadDataFn: () => Promise<void>;
} & Omit<EntitiesListRowProps<T>, 'entity' | 'selected'>;

const EntitiesList = <T extends BaseEntityNameLess>({
                                                        loading = false,
                                                        entities,
                                                        form,
                                                        onRowClick,
                                                        deleteFn,
                                                        getUrlFunc,
                                                        selectedId,
                                                        hasLink,
                                                        actions: Actions,
                                                        loadDataFn
                                                    }: Props<T>) => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [entity, setEntity] = useState<BaseEntityNameLess | null>(null);

    // const refs = useMemo(() => entities.reduce((acc, entity) => {
    //     if (entity['id']) {
    //         acc[entity.id] = React.createRef();
    //     }
    //     return acc;
    // }, {}), [entities]);

    // useEffect(() => {
    //     if (selectedId && refs[selectedId].current) {
    //         refs[selectedId].current.scrollIntoView({
    //             behavior: 'smooth',
    //             block: 'start',
    //         });
    //     }
    // }, [selectedId]);

    const entityDeleteHandler = ({entity}: { entity: BaseEntityNameLess }) => {
        setEntity(entity);
        setShowDeleteModal(true);
    }

    return (
        <div className="entities-list" data-testid="entitiesList">
            <LoadingBackdrop isVisible={loading} transparent>
                {
                    entities?.map(entity =>
                        <EntitiesListRow
                            key={entity.id}
                            entity={entity}
                            deleteFn={entityDeleteHandler}
                            onRowClick={onRowClick as any}
                            form={form}
                            selected={entity.id === selectedId}
                            // ref={refs[entity.id]}
                            getUrlFunc={getUrlFunc as any}
                            hasLink={hasLink}
                            {...(Actions ? {actions: <Actions id={entity?.id} loadEntitiesFn={loadDataFn}/>} : {})}
                        />
                    )
                }
                {
                    showDeleteModal && entity && deleteFn
                        ? <DeleteEntityModal
                            entity={entity}
                            setEntity={setEntity}
                            setModalShow={setShowDeleteModal}
                            deleteFn={deleteFn as any}
                        />
                        : null
                }
            </LoadingBackdrop>
        </div>
    )
}

export default EntitiesList;