import React from "react";
import {Link, LinkProps} from "react-router-dom";
import './linkwrapper.scss';


type Props = {
    hasLink?: boolean;
} & LinkProps

const LinkWrapper: React.FC<Props> = ({hasLink = true, className, children, ...linkProps}) => {

    if (hasLink) {
        return (
            <Link
                className={`linkwrapper ${className}`}
                {...linkProps}
            >
                {children}
            </Link>
        )
    }

    return (
        <div className={className}>
            {children}
        </div>
    )
}

export default LinkWrapper;