import React, {useCallback, useState} from "react";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import {IconButton} from "../../../../components/buttons/IconButton/IconButton";
import {CopyIcon} from "../../../../components/icons/icons/CopyIcon";
import {getFilledFieldsObjOnFormSubmit, isErrorResponse} from "../../../../utils/utils";
import {useTranslation} from "react-i18next";
import {useCloneTransitEntities} from "../../../../hooks/transitlLines/useCloneTransitEntities";
import Portal from "../../../../components/Portal/Portal";
import {LoadingBackdrop} from "../../../../components/LoadingBackdrop/LoadingBackdrop";
import Modal from "../../../../components/Modal/Modal";
import {Form, Row} from "../../../../components/form/Form/Form";
import * as SelectBox from "../../../../components/form/SelectBox/SelectBox";
import {useToastContext} from "../../../../context/toastContext";
import {ActionsProps} from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";
import {usePresetsContext} from "../../../../context/presetsContext";


const CopyLineAction: React.FunctionComponent<ActionsProps> = ({id, loadEntitiesFn}) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const {selectedPresetId, presets} = usePresetsContext();
    const {loading, cloneLineToPreset} = useCloneTransitEntities();
    const {addToast} = useToastContext();
    const {t} = useTranslation();

    const presetsOptions = presets.map(({id, title}) => ({
        caption: title,
        value: id
    }))

    const copyBtnHandler = useCallback(async (lineId: string) => {
        setModalVisible(true);
    }, [])

    const onFormSubmit = useCallback(async (event) => {
        const formDataObj = getFilledFieldsObjOnFormSubmit(event);
        const {presetId} = formDataObj;

        const resp = await cloneLineToPreset({lineId: id, presetId: presetId.toString()});
        if (!isErrorResponse(resp)) {
            await loadEntitiesFn();
            addToast(`${t('common.save-successful')}`, 'success');
        }
        setModalVisible(false);
    }, [setModalVisible, cloneLineToPreset, selectedPresetId, id]);

    return (
        <>
            <Tooltip title={t('transit-lines.copy-line-to-preset-btn-tooltip')} placement="top">
                <IconButton
                    icon={CopyIcon}
                    width={20}
                    height={20}
                    onClick={() => copyBtnHandler(id)}
                    borderless
                />
            </Tooltip>
            {
                modalVisible ?
                    <Portal targetNodeId={"app"}>
                        <LoadingBackdrop
                            isVisible={loading}
                            transparent
                        >
                            <Modal
                                title={t("transit-lines.copy-line-to-preset-modal-title")}
                                setVisible={setModalVisible}
                            >
                                <Form
                                    name="copyLineToPresetForm"
                                    onFormSubmit={onFormSubmit}
                                    submitBtnTitle={t('common.confirm')}
                                    // disabled={!selectedPresetId}
                                >
                                    <Row>
                                        <SelectBox.Labeled
                                            label={t('transit-lines.copy-line-to-preset-select')}
                                            name='presetId'
                                            options={presetsOptions}
                                            value={selectedPresetId}
                                        />
                                    </Row>
                                </Form>
                            </Modal>
                        </LoadingBackdrop>
                    </Portal>
                    : null
            }
        </>
    )
}

export default CopyLineAction;