import React, {ChangeEvent} from 'react';
import {
  MetadataPropertyEntity,
  MetadataPropertyType
} from "../../api/entities/replancity_MetadataProperty";
import {formatNumber} from "../../utils/utils";
import * as TextField from "./TextField/TextField";
import * as ColorPickerField from "./ColorPickerField/ColorPickerField";
import * as CheckboxField from './CheckboxField/CheckboxField';
import {Option} from "./SelectBox/SelectBox";
import * as RoadSegmentField from "./RoadSegmentField/RoadSegmentField";
import * as DrawGeometryField from "./DrawGeometryField/DrawGeometryField";
import PublicTransitVehicleTypeField from "./PublicTransitVehicleTypeField/PublicTransitVehicleTypeField";
import PublicTransitFareField from "./PublicTransitFareField/PublicTransitFareField";
import * as SelectBox from "./SelectBox/SelectBox";


type Props = {
  entityId?: string;
  name: string;
  value: any;
  metadata: MetadataPropertyEntity;
  onChange?: (event: ChangeEvent<HTMLElement>) => void;
  alertLabel?: string;
  visible?: boolean;
  autoFocus?: boolean;
}

const FieldCreator = React.memo(React.forwardRef(({
                                                    entityId,
                                                    name,
                                                    value,
                                                    metadata,
                                                    onChange,
                                                    alertLabel,
                                                    visible = true,
                                                    autoFocus
                                                  }: Props, ref: React.ForwardedRef<HTMLElement>) => {
  const {options, printedName, type, mandatory, showIf, validationRegex} = metadata;

  //TODO probably type check and consequent options generation should be done in some classes/utils
  const mappedSelectOptions: Option[] = options.map(({id, propertyName}) => ({
    value: type === 'Enum' ? propertyName : id,
    caption: propertyName
  }));

  const getField = (
    selectOptions: Option[],
    label: string,
    type: MetadataPropertyType,
    value: any,
    onChange?: (event: ChangeEvent<HTMLElement>) => void,
    alertLabel?: string,
    entityId?: string,
    autoFocus?: boolean,
    validationRegex?: string
  ) => {
    switch (type) {
      case 'String':
      case 'Project':
      case 'UUID':
        return <TextField.Labeled
          name={name}
          label={label}
          alertLabel={alertLabel}
          type="text"
          required={!!mandatory}
          value={value}
          onChange={onChange}
          ref={ref as any}
          visible={visible}
          autoFocus={autoFocus}
          validationRegex={validationRegex}
        />
      case 'LocalTime':
      case 'DEPARTURE':
      case 'INTERVAL':
        return <TextField.Labeled
          name={name}
          label={label}
          alertLabel={alertLabel}
          type="time"
          required={!!mandatory}
          value={value}
          step="1"
          onChange={onChange}
          ref={ref as any}
          visible={visible}
          testId={name}
          validationRegex={validationRegex}
        />
      case 'Integer':
      case 'Double':
        return <TextField.Labeled
          name={name}
          label={label}
          alertLabel={alertLabel}
          type="text"
          required={!!mandatory}
          value={formatNumber(value)}
          onChange={onChange}
          ref={ref as any}
          visible={visible}
          validationRegex={validationRegex}
        />
      case "Boolean":
        return <CheckboxField.Labeled
          name={name}
          value={value}
          alertLabel={alertLabel}
          label={label}
          onChange={onChange}
          required={!!mandatory}
          visible={visible}
        />
      case "Color":
        return <ColorPickerField.Labeled
          name={name}
          value={value as string}
          alertLabel={alertLabel}
          label={label}
          onChange={onChange}
          required={!!mandatory}
          visible={visible}
        />
      case 'Enum':
        return <SelectBox.Labeled
            label={label}
            alertLabel={alertLabel}
            name={name}
            options={mappedSelectOptions}
            value={value}
            required={!!mandatory}
            onChange={onChange}
            ref={ref}
            visible={visible}
          />
      case 'PublicTransitFareOptions':
        return <PublicTransitFareField
          label={label}
          alertLabel={alertLabel}
          name={name}
          options={selectOptions}
          value={value}
          required={!!mandatory}
          onChange={onChange}
          ref={ref as any}
          visible={visible}
        />
      case 'PublicTransitVehicleType':
        return <PublicTransitVehicleTypeField
          label={label}
          alertLabel={alertLabel}
          name={name}
          options={selectOptions}
          value={value}
          required={!!mandatory}
          onChange={onChange}
          visible={visible}
        />
      case 'Point':
      case 'LineString':
      case 'Polygon':
        return <DrawGeometryField.Labeled
          label={label}
          alertLabel={alertLabel}
          name={name}
          value={value}
          drawMode={type}
          required={!!mandatory}
          onChange={onChange}
          visible={visible}
        />
      case 'RoadInfrastructureSegment':
        return <RoadSegmentField.Labeled
          label={label}
          alertLabel={alertLabel}
          name={name}
          value={value}
          required={!!mandatory}
          onChange={onChange}
          visible={visible}
        />
      default:
        return <TextField.Labeled
          name={name}
          label={label}
          alertLabel={alertLabel}
          type="text"
          required={!!mandatory}
          value={value}
          onChange={onChange}
          ref={ref as any}
          visible={visible}
          autoFocus={autoFocus}
          validationRegex={validationRegex}
        />
    }
  }

  return getField(mappedSelectOptions, printedName, type, value, onChange, alertLabel, entityId, autoFocus, validationRegex);
}));

export default FieldCreator;