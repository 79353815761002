import restClient from "./RestClient";


export const publicTransitApi = {
    cloneLineToPreset: function (
        data: { presetId: string, lineId: string },
        abortSignal: AbortSignal
    ):
        Promise<string[]> {
        return restClient.fetch({
            method: "GET",
            path: `services/replancity_PublicTransitService/cloneLine`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

    cloneRouteToLine: function (
        data: { routeId: string, lineId: string },
        abortSignal: AbortSignal
    ):
        Promise<string[]> {
        return restClient.fetch({
            method: "GET",
            path: `services/replancity_PublicTransitService/cloneRouteToLine`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

    invertRoute: function (
        data: { routeId: string},
        abortSignal: AbortSignal
    ):
        Promise<string[]> {
        return restClient.fetch({
            method: "GET",
            path: `services/replancity_PublicTransitService/invertRoute`,
            data,
            fetchOptions: {signal: abortSignal}
        });
    },

};