import React from "react";
import DropDown, {OptionsAlign} from "../DropDown/DropDown";
import {themeColors} from "../../styles/theme";
import {DropDownOptionType} from "../DropDown/Option";
import {useTranslation} from "react-i18next";
import {useTheme} from "../../context/themeContext";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectCurrentProjectModes} from "../../redux/selectors/selectors";
import {DEFAULT_MODE} from "../../hooks/projects/useAllowedModes";
import {useMatch} from "react-router-dom";
import {AllowedProjectModes} from "../../redux/reducers/projectsReducer";


const ProjectModeSelector = () => {
    const match = useMatch('/:projectId/:mode/*');
    const mode = match?.params?.mode ? match.params.mode! : DEFAULT_MODE;
    const projectId = useTypedSelector(selectCurrentProjectId);
    const allowedModes = useTypedSelector(selectCurrentProjectModes);
    const {t} = useTranslation();
    const {theme} = useTheme();
    const themeColor = theme === 'light' ? themeColors.navyBlue : themeColors.white;

  const dropDownTitleByMode = {
    'layers': t('common.layers'),
    // 'algorithms': t('common.algorithms'),
    'report': t('common.dashboard'),
    'dashboard': t('common.bi-dashboard'),
    'network': t('common.editing'),
    'transit': t('transit-lines.transit-lines-title'),
    'maas': t('drt.drt-dropdown-mode-title'),
    'config': t('projects-main.admin-settings'),
    'runs': t('projects-main.runs'),
    'ev': t('projects-main.ev'),
    'scenario': t('projects-main.scenario')
  };

  const allowedModesDontExist = Object.values(allowedModes).every(isAllowed => !isAllowed);

  const dropDownOptions: (Omit<DropDownOptionType, 'testId'> & {testId?: keyof AllowedProjectModes})[] = [
    {
        title: t('common.layers'),
        link: `/${projectId}/`,
        testId: 'layers'
    },
    // ...(
    //   allowedModes.runOptimization ? [{
    //     title: t('common.algorithms'),
    //     link: `/${projectId}/algorithms`,
    //   }] : []
    // ),
    ...(
      allowedModes.report ? [{
        title: t('common.dashboard'),
        link: `/${projectId}/report`,
        testId: 'report'
      } as const] : []
    ),
    ...(
      allowedModes.biDashboard ? [{
        title: t('common.bi-dashboard'),
        link: `/${projectId}/dashboard`,
        testId: 'biDashboard'
      } as const] : []
    ),
    ...(
      allowedModes.networkEditor ? [{
        title: t('common.editing'),
        link: `/${projectId}/network`,
        testId: 'networkEditor'
      } as const] : []
    ),
    ...(
      allowedModes.transitScheduleEditor ? [{
        title: t('transit-lines.transit-lines-title'),
        link: `/${projectId}/transit`,
        testId: 'transitScheduleEditor'
      } as const] : []
    ),
    ...(
      allowedModes.sharedMobilityEditor ? [{
        title: t('drt.drt-dropdown-mode-title'),
        link: `/${projectId}/maas`,
        testId: 'sharedMobilityEditor'
      } as const] : []
    ),
  ...(
      allowedModes.advancedConfig ? [{
        title: t('projects-main.admin-settings'),
        link: `/${projectId}/config`,
        testId: 'advancedConfig'
      } as const] : []
    ),
  ...(
      allowedModes.runs ? [{
        title: t('projects-main.runs'),
        link: `/${projectId}/runs`,
        testId: 'runs'
      } as const] : []
    ),
    ...(
      allowedModes.evInfrastructureEditor ? [{
        title: t('projects-main.ev'),
        link: `/${projectId}/ev`,
        testId: 'evInfrastructureEditor'
      } as const] : []
    ),
    ...(
      allowedModes.scenarioPresetEditor ? [{
        title: t('projects-main.scenario'),
        link: `/${projectId}/scenario`,
        testId: 'scenarioPresetEditor'
      } as const] : []
    )
  ]

    return (
        <>
            <DropDown
                title={dropDownTitleByMode[mode]}
                options={dropDownOptions}
                optionsAlign={OptionsAlign.LEFT}
                disabled={allowedModesDontExist}
                titleColorLight={themeColors.navyBlue}
                titleColorDark={themeColors.white}
                optionColorLight={themeColors.navyBlue}
                optionColorDark={themeColors.white}
                iconColorLight={themeColors.white}
                iconColorDark={themeColors.navyBlue}
                iconRectangleWidth={35}
                tooltip={t('header.select-mode')}
                testId={'projectModesSelector'}
            />
        </>
    )
}

export default ProjectModeSelector;