import React from "react";
import {ComponentType} from "react";
import {FormFieldProps} from "../components/form/Form/Form";
import classNames from "classnames";
import {AlertRhombusIcon} from "../components/icons/icons/AlertRhombusIcon";
import {themeColors} from "../styles/theme";
import {useTheme} from "../context/themeContext";


//eslint-disable-next-line
const withClass = <P extends {}>(
    Wrapped: ComponentType<P>,
    className: string
) => {
    return (props: P) => (
        <div className={className}>
            <Wrapped {...props} />
        </div>
    );
};

const FieldWithLabel = <P extends FormFieldProps>(Wrapped: ComponentType<P>) => {
    return React.forwardRef(({inline, alertLabel, visible = true, ...props}: P,
                             ref: React.ForwardedRef<HTMLElement>) => {
        const {theme} = useTheme();

        const fieldGroupClassname = classNames(
            'form-field-group',
            inline && 'inline',
            props.fieldGroupClassName && props.fieldGroupClassName
        );

        return (
            <>
                {
                    visible ?
                        <label
                            className={fieldGroupClassname}
                            style={
                                theme === 'light' ? {color: themeColors.navyBlue} : {
                                    color: themeColors.white
                                }}
                        >
                            <div className="label-group">
                                <span>{props.label ?? props.name}</span>
                                {
                                    props.required
                                        ? <span style={{color: themeColors.invalidColor1}}>*</span>
                                        : null
                                }
                                {
                                    alertLabel ?
                                        <>
                                            <AlertRhombusIcon
                                                color={themeColors.invalidColor1}
                                            />
                                            <span
                                                style={{color: themeColors.invalidColor1}}
                                            >
                                            {alertLabel}
                                            </span>
                                        </>
                                        : null
                                }
                            </div>
                            <Wrapped
                                {...props as any}
                                ref={ref}
                            />
                        </label>
                        : null
                }
            </>
        )
    });
};

export {FieldWithLabel};
