import {useCallback, useState} from "react";
import {publicTransitApi} from "../../api/publicTransitApi";


export const useCloneTransitEntities = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const cloneLineToPreset = useCallback(async ({lineId, presetId}: {
        lineId: string;
        presetId: string;
    }): Promise<any> => {
        const abortController = new AbortController();

        setLoading(true);

        const resp = await publicTransitApi.cloneLineToPreset({
            lineId,
            presetId
        }, abortController.signal);

        setLoading(false);

        return resp;
    }, [])

    const cloneRouteToLine = useCallback(async ({routeId, lineId}: {
        routeId: string;
        lineId: string;
    }): Promise<any> => {
        const abortController = new AbortController();

        setLoading(true);

        const resp = await publicTransitApi.cloneRouteToLine({
            routeId,
            lineId
        }, abortController.signal);

        setLoading(false);

        return resp;
    }, [])

    const invertRoute = useCallback(async ({routeId}: {
        routeId: string;
    }): Promise<any> => {
        const abortController = new AbortController();

        setLoading(true);

        const resp = await publicTransitApi.invertRoute({
            routeId
        }, abortController.signal);

        setLoading(false);

        return resp;
    }, [])

    return {
        loading,
        cloneLineToPreset,
        cloneRouteToLine,
        invertRoute
    }
}