import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MarkerPoint} from "./types";


export const enum ROAD_ROUTE_CHECKER_MARKER_NAME {
    fromPoint = 'fromPoint',
    toPoint = 'toPoint'
}

type State = {
    [key in ROAD_ROUTE_CHECKER_MARKER_NAME]: MarkerPoint | null;
}

export const initialState: State = {
    [ROAD_ROUTE_CHECKER_MARKER_NAME.fromPoint]: null,
    [ROAD_ROUTE_CHECKER_MARKER_NAME.toPoint]: null,
}

const roadRouteCheckSlice = createSlice({
    name: 'roadRouteCheck',
    initialState,
    reducers: {
        setRoadRouteCheckPoint(state, action: PayloadAction<{[key in keyof typeof ROAD_ROUTE_CHECKER_MARKER_NAME]?: MarkerPoint}>) {
            const point = action.payload;
            return {
                ...state,
                ...point
            };
        },
        cleanRoadRouteCheckPoints() {
            return {
                ...initialState
            };
        },
    },
});

export const {
    setRoadRouteCheckPoint,
    cleanRoadRouteCheckPoints
} = roadRouteCheckSlice.actions;

export default roadRouteCheckSlice.reducer
