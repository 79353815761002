import React, {useState} from "react";
import {FieldWithLabel} from "../../../hoc/withClass";
import {Slider} from "@mui/material";
import {useTheme} from "../../../context/themeContext";
import {styled} from "@mui/material/styles";
import {themeColors} from "../../../styles/theme";
import {FormFieldProps} from "../Form/Form";
import {SliderOwnProps} from "@mui/material/Slider/Slider";
import './slider-field.scss';


export const StyledSlider = styled((props: SliderOwnProps) => (
    <Slider {...props} />
))(() => {
    const {theme} = useTheme();
    const color = theme === 'light' ? themeColors.navyBlue : themeColors.white;

    return {
        '& .MuiSlider-markLabel': {
            color
        }
    }
});

type Props = {
    name: string;
    floatLabels?: boolean;
} & Omit<SliderOwnProps, 'name' | 'onChange'> & Omit<FormFieldProps, 'name' | 'value' | 'onChange'>;

const SliderField = ({label, name, min, max, step, defaultValue, floatLabels = true, required}: Props) => {
    const [valueState, setValueState] = useState<SliderOwnProps['defaultValue']>(defaultValue);

    const marks: { value: number; label: string; }[] = [];
    if (min !== undefined && max !== undefined && step) {
        for (let i = min; i < max; i += step) {
            marks.push({
                value: i,
                label: `${floatLabels ? parseFloat(i.toString()).toFixed(1) : i}`,
            });
        }
    }

    const changeHandler = (event: Event, newValue: number | number[]) => {
        setValueState(newValue);
    };

    return (
        <div className="slider-field">
            <StyledSlider
                value={valueState}
                valueLabelDisplay="auto"
                marks={marks}
                step={step}
                min={min}
                max={max}
                onChange={changeHandler}
            />
            <input
                name={name}
                type="text"
                value={Array.isArray(valueState) ? JSON.stringify(valueState) : valueState}
                onChange={() => {
                    return;
                }}
                hidden
            />
        </div>
    )
}

const Labeled = FieldWithLabel<Props>(SliderField);

export {Labeled, SliderField}

export default SliderField;