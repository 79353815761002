import React, {Dispatch, SetStateAction, useCallback, useState} from "react";
import Modal from "../../Modal/Modal";
import Button from "../../buttons/Button";
import Portal from "../../Portal/Portal";
import {useTranslation} from "react-i18next";
import {BaseEntityNameLess} from "../../../api/entities/BaseEntity";
import './delete-entity-modal.scss';
import classNames from "classnames";


type Props = {
    entity: BaseEntityNameLess;
    setEntity?: Dispatch<SetStateAction<BaseEntityNameLess | null>>;
    setModalShow: Dispatch<SetStateAction<boolean>>;
    deleteFn: ({entity}: { entity: BaseEntityNameLess }) => Promise<void>;
}

const DeleteEntityModal = ({entity, setEntity, setModalShow, deleteFn}: Props) => {
    const [blocked, setBlocked] = useState<boolean>(false);
    const {t} = useTranslation();

    const classes = classNames(
        "delete-confirmation-modal",
        blocked && 'disabled'
    )

    const cancelHandler = useCallback(() => {
        setModalShow?.(false);
    }, [setModalShow])

    const deleteHandler = useCallback(async () => {
        setBlocked(true);

        await deleteFn({entity});
        setEntity?.(null);
        setModalShow?.(false);

        setBlocked(false);
    }, [entity, setModalShow, deleteFn])

    return (
        <>
            {
                entity
                    ? <Portal targetNodeId={"app"}>
                        <Modal
                            title={t('common.delete-confirmation')}
                            setVisible={setModalShow}
                            testId="deleteConfirmationModal"
                        >
                            <div className={classes}>
                                <Button
                                    onClick={cancelHandler}
                                    text={t("common.cancel")}
                                    colorType="transparent"
                                />
                                <Button
                                    type="submit"
                                    onClick={deleteHandler}
                                    text={t("common.delete")}
                                    colorType="dark"
                                    testId="deleteConfirmationModalDeleteBtn"
                                />
                            </div>
                        </Modal>
                    </Portal>
                    : null
            }
        </>
    )
}

export default DeleteEntityModal;