import * as React from "react";
import {Route} from "react-router-dom";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";


const ConfigPage = React.lazy(() => import('./ConfigPage'));

export const useConfigurationRoutes = () => {
    return (
        <Route path="config" element={<LazyLoadingWrapper element={<ConfigPage/>}/>}/>
    );
}