import {RootState} from "../reducers";
import {createSelector} from "reselect";
import {ProjectEntity} from "../../api/entities";
import {isLayerVisibleOnMap, isLayerVisibleInList} from "../../utils/mapUtils";
import {DataById, DeckLayer, Entity, LayerTypes, NamedLayer, SourceItem} from "../map/types";
import {AllowedProjectModes} from "../reducers/projectsReducer";
import {DeckLayerType, MapboxLayerType} from "../../api/enums/enums";


export const selectCurrentProjectId = (state: RootState): string => state.projects.currentProjectId;

export const selectCurrentProjectModes = (state: RootState): AllowedProjectModes => state.projects.allowedModes;

export const selectProjectById = (state: RootState, projectId: string): ProjectEntity => state.projects.projectsById[projectId];

export const selectProjectsById = (state: RootState) => state.projects.projectsById;

export const selectCurrentProjectBorders = createSelector([
    selectCurrentProjectId,
    selectProjectsById
  ],
  (id, projectsById) => projectsById[id]?.simulationRegionInJsonFormat
);

export const selectCurrentProjectCenter = createSelector([
    selectCurrentProjectId,
    selectProjectsById
  ],
  (id, projectsById) => projectsById[id]?.centerCoord
);

export const selectProjects = createSelector([
        selectProjectsById
    ],
    (projectsById) => Object.values(projectsById)
);

export const selectCurrentProjectName = createSelector([
    selectCurrentProjectId,
    selectProjectsById
  ],
  (id, projectsById) => projectsById[id]?.projectName
);

export const selectProjectPresets = (state: RootState) => state.projects.presets;

export const selectSelectedPresetId = (state: RootState): string => state.projects.selectedPresetId;

export const selectPresetType = (state: RootState): string => state.projects.presetType;

export const selectSelectedRunId = (state: RootState): string => state.projects.selectedRunId;

export const selectRunIdToCompareWith = (state: RootState): string => state.projects.runIdToCompareWith;

export const selectProjectRuns = (state: RootState) => state.projects.runs

export const selectRunningAlgorithms = (state: RootState, projectId: string) => state['algorithms'].runningAlgorithms[projectId];

export const selectCurrentProjectSimulationState = createSelector([
    selectProjectsById,
    selectCurrentProjectId
  ],
  (projectsById, id) => {
    const {simulationState, simulationProgress} = projectsById[id] ?? {};

    return {
      simulationState,
      simulationProgress
    }
  }
)
export const selectProjectSimulationStateWithAlgorithms = (state: RootState, projectId: string) => {
  const {simulationState, simulationProgress, runningAlgorithms} = state.algorithms;
  return {
    simulationState,
    simulationProgress,
    runningAlgorithms: runningAlgorithms[projectId]
  };
}

export const selectAvailableAlgorithms = (state: RootState, projectId: string) => state['algorithms'].availableAlgorithms[projectId];

//----- Layers -----------------
export const selectMapboxLayerIds = (state: RootState) => state.map.mapboxglLayers.allId;

export const selectDeckGlLayerIds = (state: RootState) => state.map.deckglLayers.allId;

export const selectAllLayersIds = createSelector([
        selectMapboxLayerIds,
        selectDeckGlLayerIds
    ],
    (mapboxLayerIds, deckGlLayerIds) => [...mapboxLayerIds, ...deckGlLayerIds]
);

export const selectMapboxLayerById = (state: RootState, layerId: string): NamedLayer => state.map.mapboxglLayers.byId[layerId];

export const selectDeckGlLayerById = (state: RootState, layerId: string): DeckLayer => state.map.deckglLayers.byId[layerId];

export const selectMapboxLayersById = (state: RootState) => state.map.mapboxglLayers.byId;

export const selectDeckGlLayersById = (state: RootState) => state.map.deckglLayers.byId;

export const selectAllLayersById = createSelector([
    selectMapboxLayersById,
    selectDeckGlLayersById
  ],
  (mapboxLayersById, deckGlLayersById) => ({...mapboxLayersById, ...deckGlLayersById})
)

export const selectLayerById = createSelector([
        selectAllLayersById,
        (state, layerId) => layerId
    ],
    (layersById, layerId) => layersById[layerId]
)

// To get all layers related to same source. i.e. labels, symbols
export const selectLayersWithSameSourceById = createSelector([
    selectAllLayersById,
    (state, sourceId) => sourceId
  ],
  (layersById, sourceId) => {
    return Object.entries(layersById).reduce((acc, [layerId, layer]) => {
      if (layer.source === sourceId) {
        acc[layerId] = layer;
      }
      return acc;
    }, {});
  }
)

export const selectVisibleLayersById = createSelector([
    selectAllLayersById
  ],
  (layersById: DataById<NamedLayer | DeckLayer>) => {
    return Object.entries(layersById).reduce((acc, [id, layer]) => {
      if (isLayerVisibleOnMap(layer)) {
        acc[id] = layer;
      }
      return acc;
    }, {});
  }
)

//----- Sources -----------------
export const selectSourcesById = createSelector([
    (state: RootState) => state.map.sources
  ],
  (sources: Entity<SourceItem>) => sources.byId
)

export const selectMapboxGlSourcesById = createSelector([
        selectSourcesById
    ],
    (sourcesById: DataById<SourceItem>) => {
        return Object.values(sourcesById)
            .reduce((acc, source) => {
                if (source.type in MapboxLayerType) {
                    acc[source.id] = source;
                }
                return acc;
            }, {})
    }
)

export const selectDeckGlSourcesById = createSelector([
        selectSourcesById
    ],
    (sourcesById: DataById<SourceItem>) => {
        return Object.values(sourcesById)
            .reduce((acc, source) => {
                if (source.type in DeckLayerType) {
                    acc[source.id] = source;
                }
                return acc;
            }, {} as DataById<SourceItem>)
    }
)

export const selectClickableSourceIds = createSelector([
    selectSourcesById
  ],
  (sourcesById: DataById<SourceItem>) => {
    return Object.values(sourcesById)
      .filter(({clickable}) => clickable)
      .map(({id}) => id);
  }
)

export const selectClickableMapboxSourcesIds = createSelector([
    selectMapboxLayersById
  ],
  (mapboxLayersById) => {
    return Object.values(mapboxLayersById)
      .filter(({clickable}) => clickable)
      .map(({id}) => id);
  }
)

export const selectAllSourceIds = (state: RootState) => state.map.sources.allId;

export const selectSourceById = createSelector([
    selectSourcesById,
    (state: RootState, sourcesId: string) => sourcesId
  ],
  (sourcesById: DataById<SourceItem>, sourceId) => sourcesById[sourceId]
)

export const selectIfSourceReloadRequired = createSelector([
    selectSourceById
  ],
  (source) => source?.isReloadRequired
)

export const selectSourcesDataById = (state: RootState) => state.map.dataBySourceId;

export const selectTripsSource = createSelector([
        selectAllSourceIds,
        selectSourcesById
    ],
    (ids, sourcesById) => {
        for (const id of ids) {
            if (sourcesById[id].type === DeckLayerType.TRIPS) {
                return sourcesById[id];
            }
        }
    }
)

export const selectDeckGlSourcesWithoutTripsById = createSelector([
        selectTripsSource,
        selectDeckGlSourcesById
    ],
    (tripsSource, sourcesById) => {
        if (tripsSource?.id) {
            const {[tripsSource.id]: removedSource, ...restSources} = sourcesById;
            return restSources;
        }
        return sourcesById;
    }
)

export const selectSourcesWithoutTripsById = createSelector([
        selectMapboxGlSourcesById,
        selectDeckGlSourcesWithoutTripsById
    ],
    (mapboxGlSourcesById, deckGlSourcesById) => ({...mapboxGlSourcesById, ...deckGlSourcesById})
)

export const selectSourceDataById = createSelector([
        selectSourcesDataById,
        (state: RootState, sourcesId: string) => sourcesId
    ],
    (dataBySourceId, sourcesId) => dataBySourceId[sourcesId]
)

export const selectSourceDataByIdExists = createSelector([
        selectSourceDataById
    ],
    (data) => !!data.features.length
)


export const selectFeatureCollectionOfVisibleLayers = createSelector([
    selectVisibleLayersById,
    selectSourcesDataById
  ],
  (visibleLayersById: DataById<LayerTypes>, sourcesDataById: DataById<GeoJSON.FeatureCollection>) => {
    return Object.keys(visibleLayersById).reduce((acc, id) => {
      if (sourcesDataById?.[id]?.features?.length) {
        acc.features.concat(sourcesDataById[id].features);
      }
      return acc;
    }, {
      type: "FeatureCollection",
      features: []
    } as GeoJSON.FeatureCollection);
  }
)

export const selectMapLayerIdsByEntityNames = createSelector([
        selectSourcesById,
    ],
    (sourcesById) => {
        return Object.entries(sourcesById).reduce((acc, [id, {entityName}]) => {
            acc[entityName] = id;
            return acc;
        }, {})
    }
)

export const selectMapLayerIdByEntityName = createSelector([
        selectMapLayerIdsByEntityNames,
        (_, entityName) => entityName
    ],
    (sourceIdsByEntityName, entityName) => sourceIdsByEntityName[entityName]
)

export const selectDeckGlLayersDataById = createSelector([
        selectDeckGlSourcesById,
        selectSourcesDataById
    ],
    (sourcesByIds, sourcesDataById) => {
        return Object.keys(sourcesByIds).reduce((acc, id) => {
            acc[id] = sourcesDataById[id];
            return acc;
        }, {})
    }
)

export const selectSourceLegendsById = (state: RootState) => state.map.legendsBySourceId;

export const selectLegendsForVisibleLayersById = createSelector([
    selectAllLayersById,
    selectSourceLegendsById
  ],
  (layersByIds, legendsById) => {
    return Object.entries(layersByIds).reduce((acc, [id, layer]) => {
      if (isLayerVisibleOnMap(layer) && isLayerVisibleInList(layer) && legendsById[id]) {
        acc[id] = {...legendsById[id]};
        acc[id]['layerName'] = layer.layerName;
      }
      return acc;
    }, {})
  }
)

export const selectSourceDistributionsById = (state: RootState) => state.map.distributionsById;

export const selectDistributionsForVisibleLayersById = createSelector([
    selectAllLayersById,
    selectSourceDistributionsById
  ],
  (layersByIds, legendsById) => {
    return Object.entries(layersByIds).reduce((acc, [id, layer]) => {
      if (isLayerVisibleOnMap(layer) && isLayerVisibleInList(layer) && legendsById[id]) {
        acc[id] = legendsById[id];
      }
      return acc;
    }, {})
  }
)

export const selectProjectDateTypes = (state: RootState) => state.map.dateTypesById;

export const selectCurrentDateType = (state: RootState) => state.map.dateTypesById[state.map.selectedDateTypeId];

export const selectCalculationState = (state: RootState) => state.map.calculationStateBySourceId;

export const selectLayerCalculationState = (state: RootState, id: string) => state.map.calculationStateBySourceId[id];

export const selectMapConfigCoordinates = createSelector([
        selectProjectsById,
        selectCurrentProjectId
    ],
    (projectsById, projectId) => {
        const {centerCoord} = projectsById[projectId];
        if (centerCoord) {
            const [latitude, longitude] = centerCoord.split(',');
            return {latitude: parseFloat(latitude), longitude: parseFloat(longitude)};
        }
        return {latitude: 0, longitude: 0};
    }
);

export const selectViewPortCoordinates = (state: RootState) => state.map.viewPortCoordinates;

export const selectIsViewPortFixed = (state: RootState) => state.map.isViewPortFixed;

export const selectPointToFlyTo = (state: RootState) => state.map.flyToPoint;

export const selectRequireToHighlightFeatures = (state: RootState) => state.map.requireToHighlightFeatures;

export const selectRedirectOnFeatureSelection = (state: RootState) => state.map.redirectOnFeatureSelection;

export const selectMapConfigReloadRequired = (state: RootState) => state.map.mapConfigReloadRequired;

//------------ ShowInfo -----------------------------

export const selectSelectedFeatures = (state: RootState) => state.showInfo.selectedFeatures;

export const selectSelectedCoordinates = (state: RootState) => state.showInfo.lngLat;

export const selectSelectedFeaturesForResponsiveLayer = createSelector([
    selectSelectedFeatures,
    selectSelectedCoordinates,
    selectSourceById
  ],
  (features, lngLat, source) => {
    const {updateOnFeatureSelection} = source;
    if (!updateOnFeatureSelection) {
      return null;
    }

    return {features, lngLat};
  }
)

//------------ Draw -----------------------------
export const selectIsDrawMode = (state: RootState) => state.draw.isDrawMode;

export const selectEnabledFeaturesEditing = (state: RootState) => state.draw.editingEnabled;

export const selectDrawMode = (state: RootState) => state.draw.drawMode;

export const selectSnapMode = (state: RootState) => state.draw.snapMode;

export const selectCutMode = (state: RootState) => state.draw.cutMode;

export const selectDrawnFeatures = (state: RootState) => state.draw.drawnFeatures;

//------------ Entity -----------------------------
export const selectEntity = (state: RootState, entityName: string) => state.entity.dataByEntityName[entityName];

export const selectEntityMetadata = (state: RootState, entityName: string) => state.entity.metadataByEntityName[entityName];

export const selectFeaturesWithParentsByEntityName = (state: RootState) => state.entity.featuresWithParentByEntityName;

export const selectFeatureWithParentByEntityName = (state: RootState, entityName: string) => state.entity.featuresWithParentByEntityName[entityName];

//------------ Road route checker -----------------------------
export const selectRoadRouteCheckerPoint = (state: RootState, markerElementId: string) => state.roadRouteCheck[markerElementId];

export const selectRoadRouteCheckerMarkersCoordinates = (state: RootState) => state.roadRouteCheck;
