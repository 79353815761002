import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useTypedSelector} from "../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectProjectPresets, selectSelectedPresetId} from "../redux/selectors/selectors";
import {getLocalStorageItem, isErrorResponse} from "../utils/utils";
import {addedPresets, ProjectPreset, setSelectedPreset} from "../redux/reducers/projectsReducer";
import {useDispatch} from "react-redux";
import {PublicTransitPresetEntity} from "../api/entities/replancity_PublicTransitPreset";
import {presetsApi} from "../api/presetsApi/presetsApi";
import {ApiError} from "../api/RestClient";
import {CopyPresetFnArgs} from "../api/presetsApi/types";
import {entityApi} from "../api/entityApi";


export type PresetEntityName =
    | 'replancity_PublicTransitPreset'
    | 'replancity_RoadInfrastructurePreset'
    | 'replancity_MaasPreset'
    | 'replancity_EvPreset'
    | 'replancity_ScenarioCasePreset';

export type AddPresetForm = {
    serviceName: string;
    data: {
        sourcePresetId?: string;
        presetName: string;
    }
}

const PresetsContext = createContext({
    loading: false,
    loadPresets: (presetEntityName: PresetEntityName): Promise<void> => {
        return Promise.resolve();
    },
    presets: [] as ProjectPreset[],
    selectedPresetId: '',
    addPreset: (presetEntityName: PresetEntityName, args: AddPresetForm) => {
        return {} as Promise<PublicTransitPresetEntity | ApiError>;
    },
    selectPreset: (id: string, presetEntityName: PresetEntityName) => {
        return;
    }
});

const usePresetsContext = () => {
    return useContext(PresetsContext);
};

const PresetsProvider = ({children}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const presets: ProjectPreset[] = useTypedSelector(selectProjectPresets);
    const selectedPresetId = useTypedSelector(selectSelectedPresetId);
    const dispatch = useDispatch();
    const abortControllerRef = useRef(new AbortController());

    const getPresetFromLocalStorage = useCallback((presetEntityName: string): string | undefined => {
        const presets: string = getLocalStorageItem("replanCity.presetEntityNameById");
        return presets[presetEntityName];
    }, [])

    const savePresetToLocalStorage = useCallback((presetId: string, presetEntityName: string): void => {

        let presetEntityNameById = getLocalStorageItem("replanCity.presetEntityNameById");
        if (!presetEntityNameById) {
            presetEntityNameById = {};
        }
        presetEntityNameById[presetEntityName] = presetId;

        localStorage.setItem('replanCity.presetEntityNameById', JSON.stringify(presetEntityNameById));
    }, [])

    const loadPresets = useCallback(async (presetEntityName: PresetEntityName) => {
        setLoading(true);

        const resp: PublicTransitPresetEntity[] = await presetsApi.getPresets({
            projectId,
            presetEntityName,
            abortSignal: abortControllerRef.current.signal
        });

        if (!isErrorResponse(resp)) {
            let storedPresetId = getPresetFromLocalStorage(presetEntityName);
            let storedPresetExists = false;

            const options: ProjectPreset[] = resp.map(({id, name, defaultPreset}) => {
                    if (!storedPresetId && defaultPreset) {
                        storedPresetId = id;
                    }
                    if (storedPresetId === id) {
                        storedPresetExists = true;
                    }

                    return {
                        id,
                        title: name,
                        defaultPreset
                    }
                }
            )

            dispatch(addedPresets(options));
            if (resp.length) {
                const presetId = storedPresetId && storedPresetExists ? storedPresetId : resp[0].id;
                dispatch(setSelectedPreset({presetId, presetType: presetEntityName}));
                savePresetToLocalStorage(presetId, presetEntityName);
            }
        }

        setLoading(false);
    }, [])

    const cleanPresets = useCallback(() => {
        dispatch(addedPresets());
        dispatch(setSelectedPreset());
        localStorage.removeItem('replanCity.presetEntityNameById');
    }, []);


    useEffect(() => {
        return () => {
            cleanPresets();
        }
    }, []);

    const selectPreset = useCallback((id: string, presetEntityName: PresetEntityName) => {
        savePresetToLocalStorage(id, presetEntityName);
        dispatch(setSelectedPreset({presetId: id, presetType: presetEntityName}));
    }, []);

    const addPreset = useCallback(async (presetEntityName: PresetEntityName, args: AddPresetForm): Promise<PublicTransitPresetEntity | ApiError> => {
        setLoading(true);

        let resp;
        if (args.data['sourcePresetId'] && args['serviceName']) {
            resp = await presetsApi.copyPreset({
                ...args,
                abortSignal: abortControllerRef.current.signal
            } as CopyPresetFnArgs);
        } else {
            const {presetName, ...restData} = (args).data;

            resp = await entityApi.saveEntity<typeof presetEntityName>({
                entityName: presetEntityName,
                data: {
                    project: {
                        id: projectId
                    },
                    ...restData,
                    name: presetName
                },
                abortSignal: abortControllerRef.current.signal
            });
        }

        if (!isErrorResponse(resp)) {
            await loadPresets(presetEntityName);

            const {id} = resp;
            selectPreset(id, presetEntityName);
        }
        setLoading(false);

        return resp;
    }, [projectId])

    const value = {
        loading,
        loadPresets,
        presets,
        selectedPresetId,
        addPreset,
        selectPreset
    }

    return (
        <PresetsContext.Provider value={value}>
            {children}
        </PresetsContext.Provider>
    );
};

export {PresetsProvider, usePresetsContext};