import {IconProps} from "../icons";


export const CutIcon = ({width = 12, height = 12, color}: IconProps) => {
    return (
        <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 14H3.5V3.5H14V14ZM4.66667 12.8333H12.8333V4.66667H4.66667V12.8333ZM2.33333 10.5H0V8.16667H1.16667V9.33333H2.33333V10.5ZM1.16667 7H0V3.5H1.16667V7ZM10.5 2.33333H9.33333V1.16667H8.16667V0H10.5V2.33333ZM1.16667 2.33333H0V0H2.33333V1.16667H1.16667V2.33333ZM7 1.16667H3.5V0H7V1.16667Z"
                fill={color}/>
        </svg>
    )
}