import React, {useEffect, useRef} from "react";
import './project-page.scss';
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectCurrentProjectId,
    selectCurrentProjectName,
    selectMapConfigCoordinates,
} from "../../redux/selectors/selectors";
import Header from "../../components/Header";
import ProjectModeSelector from "../../components/ProjectModeSelector/ProjectModeSelector";
import ExportMatsimButton from "../../components/ExportMatsimButton/ExportMatsimButton";
import SearchHeader from "../../components/SearchHeader";
import {CoordinatesType} from "../../redux/map/types";
import {useNavigate} from "react-router-dom";
import MapSkeleton from "../../components/Skeletons/MapSkeleton";
import LeftPanel
    from "../../components/panels/LeftPanel";
import SkeletonComponent from "../../components/Skeletons/SkeletonComponent";
import MapSelectionArea from "../../containers/MapSelectionArea/MapSelectionArea";
import {useToastContext} from "../../context/toastContext";
import {usePresetsContext} from "../../context/presetsContext";


type Props = {
    subHeaderLeft?: React.ReactNode;
    main: React.ReactNode;
    leftPanel?: React.ReactNode;
    rightPanel?: React.ReactNode;
    leftPanelTitle?: string;
    showSkeleton?: boolean;
    skeletonExplanation?: string;
}

const ProjectPage = ({
                         subHeaderLeft,
                         main,
                         leftPanel,
                         rightPanel,
                         leftPanelTitle,
                         showSkeleton,
                         skeletonExplanation
                     }: Props) => {
    const mountedRef = useRef<boolean>(false);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const projectName = useTypedSelector(selectCurrentProjectName);
    const {selectedPresetId: presetId} = usePresetsContext();
    const {longitude, latitude} = useTypedSelector<CoordinatesType>(selectMapConfigCoordinates);
    const navigate = useNavigate();
    const {addToast} = useToastContext();

    const coordinatesExist = longitude && latitude;

    useEffect(() => {
        if (mountedRef.current) {
            navigate(`./`);
        }

        if (projectId && presetId && !mountedRef.current) {
            mountedRef.current = true;
        }

    }, [projectId, presetId]);

    //TODO add project/presets/runs loaded check, then uncomment
    // useEffect(() => {
    //     if (showSkeleton && skeletonExplanation) {
    //         addToast(skeletonExplanation, 'error');
    //     }
    // }, [showSkeleton, skeletonExplanation]);

    if (!coordinatesExist && projectId) {
        return <MapSelectionArea/>;
    }

    return (
        <div className="project-page" data-testid="projectPage">
            <Header projectName={projectName}/>
            <SearchHeader
                leftFields={subHeaderLeft}
                rightFields={
                    <>
                        <ProjectModeSelector/>
                        <ExportMatsimButton/>
                    </>
                }
            />
            <main className="project-page_main">
                {
                    showSkeleton
                        ? <MapSkeleton/>
                        : main
                }
                {
                    leftPanel
                        ? (
                            <div id="info-panels">
                                <LeftPanel
                                    title={leftPanelTitle}
                                >
                                    {
                                        showSkeleton
                                            ? <SkeletonComponent skeletonsNum={5}/>
                                            : leftPanel
                                    }
                                </LeftPanel>
                            </div>
                        )
                        : null
                }
                {
                    rightPanel
                        ? rightPanel
                        : null
                }
            </main>
        </div>
    )
}

export default ProjectPage;