import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ProjectEntity} from "../../api/entities";
import {Run} from "../../hooks/projects/useRunsLoader";
import {DataById} from "../map/types";
import {PresetEntityName} from "../../context/presetsContext";


export type ProjectPreset = {
    id: string;
    title: string;
    defaultPreset?: boolean;
}

export type SelectedPreset = {
    presetId: string;
    presetType: PresetEntityName;
}

export type AllowedProjectModes = {
    layers?: boolean;
    runOptimization: boolean;
    report: boolean;
    biDashboard: boolean;
    sharedMobilityEditor: boolean;
    networkEditor: boolean;
    transitScheduleEditor: boolean;
    advancedConfig: boolean;
    runs: boolean;
    evInfrastructureEditor: boolean;
    scenarioPresetEditor: boolean;
}

export interface IInitialState {
    currentProjectId: string;
    allowedModes: AllowedProjectModes,
    presets: ProjectPreset[];
    runs: Run[];
    selectedPresetId: string;
    presetType: PresetEntityName | '';
    selectedRunId: string;
    runIdToCompareWith: string;
    projectsById: DataById<ProjectEntity>;
}


export const DEFAULT_ALLOWED_MODES = {
    layers: true,
    runOptimization: false,
    report: false,
    biDashboard: false,
    sharedMobilityEditor: false,
    networkEditor: false,
    transitScheduleEditor: false,
    advancedConfig: false,
    runs: true,
    evInfrastructureEditor: false,
    scenarioPresetEditor: false
};

const initialState: IInitialState = {
    currentProjectId: '',
    allowedModes: DEFAULT_ALLOWED_MODES,
    presets: [],
    runs: [],
    selectedPresetId: '',
    presetType: '',
    selectedRunId: '',
    runIdToCompareWith: '',
    projectsById: {}
};

const getProjectsById = (projects: ProjectEntity[]): DataById<ProjectEntity> => {
    return projects.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
    }, {})
}

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        addedProjectId(state, action: PayloadAction<string | null | undefined>) {
            state.currentProjectId = action.payload ?? '';
        },
        addedProjectModes(state, action: PayloadAction<AllowedProjectModes | undefined>) {
            state.allowedModes = {...state.allowedModes, ...(action.payload ? action.payload : DEFAULT_ALLOWED_MODES)};
        },
        addedPresets(state, action: PayloadAction<ProjectPreset[] | undefined>) {
            state.presets = action.payload ?? [];
        },
        addedProjects(state, action: PayloadAction<ProjectEntity[]>) {
            state.projectsById = getProjectsById(action.payload)
        },
        setSelectedPreset(state, action: PayloadAction<Partial<SelectedPreset> | undefined>) {
            const {presetId, presetType} = action.payload ?? {};
            state.selectedPresetId = presetId ?? '';
            state.presetType = presetType ?? '';
        },
        addedRuns(state, action: PayloadAction<Run[] | undefined>) {
            state.runs = action.payload ?? [];
        },
        setSelectedRunId(state, action: PayloadAction<string | undefined>) {
            state.selectedRunId = action.payload ?? '';
        },
        setRunIdToCompareWith(state, action: PayloadAction<string | undefined>) {
            state.runIdToCompareWith = action?.payload ?? '';
        },
    },
})

export const {
    addedProjectId,
    addedProjectModes,
    addedPresets,
    addedProjects,
    setSelectedPreset,
    addedRuns,
    setSelectedRunId,
    setRunIdToCompareWith
} = projectsSlice.actions

export default projectsSlice.reducer
