import React, {useEffect} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {selectCurrentProjectId, selectProjectsById} from "../../redux/selectors/selectors";
import {isUuidString} from "../../utils/utils";
import {LoadingComponent} from "../../components/LoadingComponent/LoadingComponent";
import {useAllowedModes} from "../../hooks/projects/useAllowedModes";
import {addedProjectId} from "../../redux/reducers/projectsReducer";
import ErrorComponent from "../../components/ErrorComponent/ErrorComponent";
import {PresetsProvider} from "../../context/presetsContext";


const ProjectView = () => {
    const {projectId} = useParams<{ projectId: string; }>();
    const currentProjectId = useTypedSelector(selectCurrentProjectId);
    const projectsById = useTypedSelector(selectProjectsById);
    const {loaded: modesLoaded, error, isModeAllowed} = useAllowedModes();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            dispatch(addedProjectId());
        }
    }, []);

    useEffect(() => {
        if (modesLoaded && !isModeAllowed) {
            navigate('/', {replace: true});
        }
    }, [modesLoaded, isModeAllowed])

    //TODO probably this should be done on upper "Page" level => pass projectId prop
    useEffect(() => {
        if (Object.entries(projectsById).length) {
            if (!isUuidString(projectId) || !projectsById[projectId!]) {
                navigate('/', {replace: true});
            }

            dispatch(addedProjectId(projectId));
        }
    }, [projectId, projectsById])

    return (
        <ErrorComponent error={error}>
            <LoadingComponent isLoading={!currentProjectId || !modesLoaded}>
                <PresetsProvider>
                    <Outlet/>
                </PresetsProvider>
            </LoadingComponent>
        </ErrorComponent>
    );
};

export default ProjectView;
