import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Form, Row} from "../../../../components/form/Form/Form";
import {MapMarkerField} from "../../../../components/form/MapMarkerField/MapMarkerField";
import {getFilledFieldsObjOnFormSubmit, isErrorResponse} from "../../../../utils/utils";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {
    selectMapLayerIdByEntityName,
} from "../../../../redux/selectors/selectors";
import {roadManagementApi} from "../../../../api/roadManagementApi";
import * as SelectBox from "../../../../components/form/SelectBox/SelectBox";
import {Option} from "../../../../components/form/SelectBox/SelectBox";
import {LoadingBackdrop} from "../../../../components/LoadingBackdrop/LoadingBackdrop";
import {requireToHighlightFeatures} from "../../../../redux/map/map-reducer";
import {
    cleanRoadRouteCheckPoints,
    ROAD_ROUTE_CHECKER_MARKER_NAME
} from "../../../../redux/road-route-check/road-route-check-reducer";
import './road-route-checker.scss';
import {
    RoadInfrastructureRouteEntity,
} from "../../../../api/entities/replancity_RoadInfrastructureRoute";
import RoadSegmentCard from "./RoadSegmentCard";
import {useToastContext} from "../../../../context/toastContext";
import {useMapMarkersCoordinates} from "./useMapMarkersCoordinates";
import {usePresetsContext} from "../../../../context/presetsContext";


const RoadRouteChecker = () => {
    const entityName = 'replancity_RoadInfrastructureSegment';
    const sourceId = useTypedSelector((state) => selectMapLayerIdByEntityName(state, entityName));
    const [loading, setLoading] = useState<boolean>(false);
    const [routeDetails, setRouteDetails] = useState<RoadInfrastructureRouteEntity>({
        id: '',
        distance: 0,
        segments: [],
        straightDistance: 0,
        time: 0
    });
    const [modes, setModes] = useState<Option[]>([]);
    const formRef = useRef<HTMLFormElement>(null);
    const {selectedPresetId: presetId} = usePresetsContext();
    const {markersCoordinates} = useMapMarkersCoordinates({
        fromPoint: ROAD_ROUTE_CHECKER_MARKER_NAME.fromPoint,
        toPoint: ROAD_ROUTE_CHECKER_MARKER_NAME.toPoint
    });
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {addToast} = useToastContext();

    useEffect(() => {
        return () => {
            dispatch(cleanRoadRouteCheckPoints());
        }
    }, []);

    useEffect(() => {
        if (presetId) {
            const abortController = new AbortController();
            (async function () {
                setLoading(true);

                const resp = await roadManagementApi.getModes({presetId}, abortController.signal);
                if (resp && Array.isArray(resp)) {
                    // if (!isErrorResponse(resp)) {
                    setModes(resp.map(mode => ({
                        value: mode,
                    })));
                } else {
                    console.error('Replan. listAllModes response doesn\'t include array of modes');
                }

                setLoading(false);
            }());
        }
    }, [presetId]);

    const onFormSubmit = useCallback(async (event) => {
        const abortController = new AbortController();

        const formDataObj = getFilledFieldsObjOnFormSubmit(event);

        const fromPoint = JSON.parse(formDataObj.fromPoint as string);
        const toPoint = JSON.parse(formDataObj.toPoint as string);

        setLoading(true);

        const resp = await roadManagementApi.calculateAndGetRouteGeometry({
            presetId,
            fromLon: fromPoint.lng,
            fromLat: fromPoint.lat,
            toLon: toPoint.lng,
            toLat: toPoint.lat,
            mode: formDataObj.mode as string
        }, abortController.signal);

        if (isErrorResponse(resp)) {
            addToast(`${t('route-check.wrong-point-error-toast')}`, 'error');
        } else {
            const {distance, id, segments, straightDistance, time} = resp;

            setRouteDetails({distance, id, segments, straightDistance, time});
            dispatch(requireToHighlightFeatures({
                featureProperties: segments.map(({id, featureStateId, _entityName}) => ({
                    id,
                    featureStateId,
                    layerId: sourceId,
                    entityName: _entityName
                }))
            }));
        }

        setLoading(false);

        return;
    }, [entityName, presetId, sourceId])

    return (
        <div className="road-route-checker">
            <div className="road-route-checker__form">
                <div className="road-route-checker__header">

                </div>
                <LoadingBackdrop isVisible={loading} transparent>
                    <div className="entity-editor__header">
                        <div className="title">
                            {t('route-check.header-title')}
                        </div>
                    </div>
                    {
                        markersCoordinates.fromPoint && markersCoordinates.toPoint
                            ? <Form
                                name="entityForm"
                                onFormSubmit={onFormSubmit}
                                submitBtnTitle={t('route-check.form-submit-btn')}
                                ref={formRef}
                                submitBtnTestId="entityFormSubmitBtn"
                                testId="entityForm"
                            >
                                <Row>
                                    <MapMarkerField
                                        name={ROAD_ROUTE_CHECKER_MARKER_NAME.fromPoint}
                                        defaultCoordinates={markersCoordinates.fromPoint}
                                        fieldGroupClassName="map-marker-form-field"
                                    />
                                    <MapMarkerField
                                        name={ROAD_ROUTE_CHECKER_MARKER_NAME.toPoint}
                                        defaultCoordinates={markersCoordinates.toPoint}
                                        fieldGroupClassName="map-marker-form-field"
                                    />
                                </Row>
                                <Row>
                                    <SelectBox.Labeled
                                        label={t('route-check.form-transport-mode')}
                                        name="mode"
                                        options={modes}
                                    />
                                </Row>
                            </Form>
                            : null
                    }
                </LoadingBackdrop>
            </div>
            <div className="road-route-checker__result">
                {
                    routeDetails.segments.length
                        ? <>
                            <div className="road-route-checker__distances">
                                Distance: {routeDetails.distance}, straight distance: {routeDetails.straightDistance},
                                time: {routeDetails.time}
                            </div>
                            <div className="road-route-checker__segments">
                                {
                                    routeDetails.segments.map(segment => (
                                        <RoadSegmentCard
                                            key={segment.id}
                                            routeId={routeDetails.id}
                                            {...segment}
                                        />
                                    ))
                                }
                            </div>
                        </>
                        : null
                }
            </div>
        </div>
    )
}

export default RoadRouteChecker;