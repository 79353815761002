import * as React from "react";
import {Route} from "react-router-dom";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";


const BiMode = React.lazy(() => import('./BiModePage'));
const SuperSetDashboard = React.lazy(() => import('../../containers/Dashboard/SuperSetDashboard'));

export const useBiDashboardsRoutes = () => {
    return (
        <>
            <Route path="dashboard" element={<LazyLoadingWrapper element={<BiMode/>}/>}/>
            <Route path="dashboard/:runId/:dashboardId/sdashboard"
                   element={<LazyLoadingWrapper element={<SuperSetDashboard/>}/>}
            />
        </>
    )
}