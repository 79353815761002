import {createFeatureCollection} from "../../utils/mapUtils";
import {MapConfigLegendProperties} from "../../api/entities/replancity_MapConfigLegendProperties";
import {
    DataById,
    DateType,
    MapboxGlLayersType,
    Sources,
    DeckGlLayersType,
    LayerVisibility,
    LayerTypes,
    LayerType,
    FeatureProperties,
    SourceItem,
} from "./types";
import {ViewPortCoordinates} from "../../api/entities/local/Borders";
import {DEFAULT_MAP_BOUNDS_COORDINATES} from "../../utils/constants";
import {MapLayerDistributionType} from "../../api/entities/replancity_MapLayer";
import {SimulationState} from "../../api/entities/replancity_RunnedAlgorithm";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SelectedFeatureProperties} from "../showInfo/types";


export type MapStoreState = {
    sources: Sources,
    mapboxglLayers: MapboxGlLayersType,
    deckglLayers: DeckGlLayersType,
    dataBySourceId: DataById<GeoJSON.FeatureCollection>,
    legendsBySourceId: DataById<MapConfigLegendProperties>,
    distributionsById: DataById<MapLayerDistributionType & { layerName: string }>
    dateTypesById: DataById<DateType>,
    calculationStateBySourceId: DataById<SimulationState>,
    viewPortCoordinates: ViewPortCoordinates,
    isViewPortFixed: boolean;
    flyToPoint: { lng: number; lat: number; };
    selectedDateTypeId: string;
    redirectOnFeatureSelection: boolean;
    requireToHighlightFeatures: SelectedFeatureProperties[];
    mapConfigReloadRequired: boolean;
}

const initialState: MapStoreState = {
    sources: {byId: {}, allId: []},
    mapboxglLayers: {
        byId: {},
        allId: []
    },
    deckglLayers: {
        byId: {},
        allId: []
    },
    dataBySourceId: {},
    legendsBySourceId: {},
    distributionsById: {},
    dateTypesById: {},
    calculationStateBySourceId: {},
    viewPortCoordinates: DEFAULT_MAP_BOUNDS_COORDINATES,
    isViewPortFixed: false,
    flyToPoint: {lng: 0, lat: 0},
    selectedDateTypeId: '',
    redirectOnFeatureSelection: true,
    requireToHighlightFeatures: [],
    mapConfigReloadRequired: false,
}

const createSource = (state: MapStoreState, source: SourceItem) => {
    const {id, calculationState} = source;

    state.sources.byId[id] = source;
    state.sources.allId.push(id);
    state.dataBySourceId[id] = createFeatureCollection();
    if (calculationState) {
        state.calculationStateBySourceId[id] = calculationState as SimulationState;
    }
}

const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        addedSources(state, action: PayloadAction<SourceItem | SourceItem[]>) {
            if (Array.isArray(action.payload)) {
                for (const source of action.payload) {
                    createSource(state, source);
                }
            } else {
                createSource(state, action.payload);
            }
        },
        addedSourceData(state, action: PayloadAction<{
            sourceId: string,
            data: GeoJSON.FeatureCollection,
            legend?: MapConfigLegendProperties | null
            distribution?: (MapLayerDistributionType & { layerName: string }) | null
        }>) {
            const {sourceId, legend, distribution, data} = action.payload;
            // const {type, features} = data ?? {type: 'FeatureCollection', features: []};

            state.dataBySourceId[sourceId] = data;
            if (legend) {
                state.legendsBySourceId[sourceId] = legend;
            }
            if (distribution) {
                state.distributionsById[sourceId] = distribution;
            }
        },
        clearAllSourcesData(state) {
            state.dataBySourceId = {};
        },
        clearSourceData(state, action: PayloadAction<string>) {
            state.dataBySourceId[action.payload] = createFeatureCollection();
        },
        addedLayers(state, action: PayloadAction<LayerTypes | LayerTypes[]>) {
            if (Array.isArray(action.payload)) {
                for (const layer of action.payload) {
                    const {id, layerType} = layer;
                    state[layerType].byId[id] = layer;
                    state[layerType].allId.push(id);
                }
            } else {
                const {id, layerType} = action.payload;
                state[layerType].byId[id] = action.payload;
                state[layerType].allId.push(id);
            }
        },
        addedDateTypesByIds(state, action: PayloadAction<DataById<any>>) {
            state.dateTypesById = action.payload;
        },
        setViewportCoordinates(state, action: PayloadAction<{
            viewPortCoordinates: ViewPortCoordinates,
            isViewPortFixed?: boolean
        }>) {
            const {isViewPortFixed = false, viewPortCoordinates} = action.payload;
            state.viewPortCoordinates = viewPortCoordinates;
            state.isViewPortFixed = isViewPortFixed;
        },
        setZoom(state, action: PayloadAction<number>) {
            state.viewPortCoordinates.zoom = action.payload;
        },
        setMapConfigReloadRequired(state, action: PayloadAction<boolean>) {
            state.mapConfigReloadRequired = action.payload;
        },
        changedLayerVisibility(state, action: PayloadAction<{
            layer: LayerTypes,
            visible: boolean
        }>) {
            const {layer: {layerType, id}, visible} = action.payload;
            const layer = {...state[layerType].byId[id]};
            layer.layout.visibility = visible ? LayerVisibility.VISIBLE : LayerVisibility.NONE;
        },
        setLayerLoading(state, action: PayloadAction<{
            layerId: string,
            loading: boolean
        }>) {
            const {layerId, loading} = action.payload;
            if (state[LayerType.MAPBOXGL].byId[layerId]) {
                state[LayerType.MAPBOXGL].byId[layerId].loading = loading;
            } else if (state[LayerType.DECKGL].byId[layerId]) {
                state[LayerType.DECKGL].byId[layerId].loading = loading;
            }
        },
        clearedMapData(state, action: PayloadAction) {
            return {...initialState, viewPortCoordinates: state.viewPortCoordinates};
        },
        selectedDateType(state, action: PayloadAction<string>) {
            state.selectedDateTypeId = action.payload;
        },
        addedLayerSimulationState(state, action: PayloadAction<{ layerId: string; calculationState: string; }>) {
            const {layerId, calculationState} = action.payload;
            state.calculationStateBySourceId[layerId] = calculationState as SimulationState;
        },
        setSourceReloadRequired(state, action: PayloadAction<{ sourceId: string; isReloadRequired: boolean; }>) {
            const {sourceId, isReloadRequired} = action.payload;
            if (state.sources.byId[sourceId]) {
                state.sources.byId[sourceId].isReloadRequired = isReloadRequired;
            }
        },
        enabledLayersInteractivity(state, action: PayloadAction) {
            for (const layer of Object.values(state[LayerType.MAPBOXGL].byId)) {
                if (state.sources.byId[layer.id]) {
                    layer.clickable = true;
                }
            }
            for (const layer of Object.values(state[LayerType.DECKGL].byId)) {
                if (state.sources.byId[layer.id]) {
                    layer.clickable = true;
                }
            }
        },
        disabledAllLayersInteractivity(state, action: PayloadAction) {
            for (const layer of Object.values(state[LayerType.MAPBOXGL].byId)) {
                if (state.sources.byId[layer.id]) {
                    layer.clickable = false;
                }
            }
            for (const layer of Object.values(state[LayerType.DECKGL].byId)) {
                if (state.sources.byId[layer.id]) {
                    layer.clickable = false;
                }
            }
        },
        disabledLayersInteractivityExcept(state, action: PayloadAction<string>) {
            const entityName = action.payload;
            for (const layer of Object.values(state[LayerType.MAPBOXGL].byId)) {
                if (state.sources.byId[layer.id]) {
                    layer.clickable = state.sources.byId[layer.id].entityName === entityName;
                } else {
                    layer.clickable = false;
                }
            }
            for (const layer of Object.values(state[LayerType.DECKGL].byId)) {
                if (state.sources.byId[layer.id]) {
                    layer.clickable = state.sources.byId[layer.id].entityName === entityName;
                } else {
                    layer.clickable = false;
                }
            }
        },
        flewToPoint(state, action: PayloadAction<{ lng: number, lat: number } | undefined>) {
            state.flyToPoint = action.payload ?? initialState.flyToPoint;
        },
        requireToHighlightFeatures(state, action: PayloadAction<{
            featureProperties: SelectedFeatureProperties | SelectedFeatureProperties[];
            restorePreviouslyHighlighted?: boolean;
        }>) {
            const {featureProperties, restorePreviouslyHighlighted} = action.payload;
            state.requireToHighlightFeatures = [
                ...(restorePreviouslyHighlighted ? state.requireToHighlightFeatures : []),
                ...(Array.isArray(featureProperties) ? featureProperties : [featureProperties])
            ] as any;
        },
        removedFeaturesHighlighting(state, action: PayloadAction) {
            state.requireToHighlightFeatures = [];
        },
        setRedirectOnFeatureSelection(state, action: PayloadAction<boolean>) {
            state.redirectOnFeatureSelection = action.payload;
        },
    },
})

export const {
    addedSources,
    addedSourceData,
    clearAllSourcesData,
    clearSourceData,
    addedLayers,
    addedDateTypesByIds,
    setViewportCoordinates,
    setZoom,
    setMapConfigReloadRequired,
    changedLayerVisibility,
    setLayerLoading,
    clearedMapData,
    selectedDateType,
    addedLayerSimulationState,
    setSourceReloadRequired,
    enabledLayersInteractivity,
    disabledAllLayersInteractivity,
    disabledLayersInteractivityExcept,
    flewToPoint,
    requireToHighlightFeatures,
    removedFeaturesHighlighting,
    setRedirectOnFeatureSelection,
} = mapSlice.actions;

export default mapSlice.reducer
