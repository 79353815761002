import React from 'react';
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {EntityServiceName} from "../../../../api/enums/enums";
import EntitiesLoader from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";
import EntityListRowForm from "../../../../components/Entities/EntityListRowForm";
import CopyLineAction from "../ListEntityActionCopyTo/CopyLineAction";


const TransitLinesList = () => {
    const entityName = PublicTransitLayerEntityName.LINES;
    const serviceName = EntityServiceName.PUBLIC_TRANSIT;

    return (
        <EntitiesLoader
            entityName={entityName}
            serviceName={serviceName}
            form={EntityListRowForm}
            actions={CopyLineAction as any}
        />
    )
}

export default TransitLinesList;