import * as React from "react";


type Props = {
    element: React.ReactElement;
}

const LazyLoadingWrapper = ({element}: Props) => {
    return (
        <React.Suspense fallback={<>...</>}>
            {element}
        </React.Suspense>
    )
}

export default LazyLoadingWrapper;