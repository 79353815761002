import * as React from "react";
import {Route} from "react-router-dom";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";


const ReportMode = React.lazy(() => import('./ReportModePage'));

export const useReportRoutes = () => {
    return (
        <Route path="report" element={<LazyLoadingWrapper element={<ReportMode/>}/>}/>
    )
}