import {useEffect, useState} from "react";


type Props = {
    milliSeconds: number;
    message?: string;
    run?: boolean;
}

const Timer = ({milliSeconds, message, run = true}: Props) => {
    let timer = milliSeconds / 1000;
    const [seconds, setSeconds] = useState<number | string>(milliSeconds / 1000);

    useEffect(() => {
        let interval;

        if (run) {
            interval = setInterval(() => {
                setSeconds(timer);
                if (--timer < 0) {
                    timer = milliSeconds / 1000;
                }
            }, 1000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
                setSeconds(milliSeconds / 1000);
            }
        }
    }, [milliSeconds, timer, run]);

    return <div>{message} {run ? seconds : ''}</div>;
}

export default Timer;