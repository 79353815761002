import React, {useCallback, useEffect} from "react";
import {useParams} from "react-router-dom";
import EntityListRowForm from "../../../../components/Entities/EntityListRowForm";
import {PublicTransitLayerEntityName} from "../../../../api/entities/replancity_PublicTransitLine";
import {EntityServiceName} from "../../../../api/enums/enums";
import EntitiesLoader from "../../../../components/Entities/EntitiesLoader/EntitiesLoader";
import {useMapFiltersContext} from "../../../../context/mapFiltersContext";
import CopyRouteToLineAction from "../ListEntityActionCopyTo/CopyRouteToLineAction";


const TransitRoutesList = () => {
    const entityName = PublicTransitLayerEntityName.ROUTES;
    const serviceName = EntityServiceName.PUBLIC_TRANSIT;
    const {lineId} = useParams<any>();

    const {setMapFilter} = useMapFiltersContext();

    const mapFeaturesFilterFn = useCallback((entities: any[]) => {
        setMapFilter(["in", ['get', 'id'], ["literal", entities.map(entity => entity.id)]]);
    }, [setMapFilter])

    useEffect(() => {
        return () => {
            setMapFilter([]);
        }
    }, [setMapFilter])

    return (
        <EntitiesLoader
            entityName={entityName}
            serviceName={serviceName}
            parentId={lineId}
            form={EntityListRowForm}
            actions={CopyRouteToLineAction as any}
            onEntitiesLoadedFn={mapFeaturesFilterFn}
        />
    )
}

export default TransitRoutesList;