type Props = {
    title: string;
    children: any
}

const MapFiltersSection = ({title, children}: Props) => {

    return (
        <div className="map-filters__section">
            <div className="map-filters__title">{title}</div>
            <div className="map-filters__body">
                {children}
            </div>
        </div>
    )
}

export default MapFiltersSection;