import * as React from "react";
import {Route} from "react-router-dom";
import LazyLoadingWrapper from "../../components/LazyLoadingWrapper/LazyLoadingWrapper";


const RunsPage = React.lazy(() => import('./RunsPage'));

export const useRunsRoutes = () => {
    return (
        <Route path="runs" element={<LazyLoadingWrapper element={<RunsPage/>}/>}/>
    );
}