import React, {memo, useCallback, useEffect, useState} from "react";
import {dashboardApi} from "../../api/dashboardApi";
import {isErrorResponse} from "../../utils/utils";
import {useTypedSelector} from "../../redux/Hooks/storeSelectors";
import {
    selectCurrentProjectId,
    selectProjectRuns,
    selectSelectedRunId
} from "../../redux/selectors/selectors";
import {LoadingBackdrop} from "../../components/LoadingBackdrop/LoadingBackdrop";
import {DashboardTemplateEntity, DashboardType} from "../../api/entities/base/replancity_DashboardTemplate";
import DropDown, {OptionsAlign} from "../../components/DropDown/DropDown";
import {themeColors} from "../../styles/theme";
import {DropDownOptionType} from "../../components/DropDown/Option";
import './side-panel-dashboards.scss';
import {useTranslation} from "react-i18next";
import SsSidePanelDashboard from "./SsSidePanelDashboard";
import {
    DashboardEmbeddingResultEnvelopeEntity
} from "../../api/entities/local/replancity_DashboardEmbeddingResultEnvelope";
import {Link} from "react-router-dom";
import {useTheme} from "../../context/themeContext";


export const getDashboardByType = (data: DashboardEmbeddingResultEnvelopeEntity) => {
    if (data.type === 'SUPERSET') {
        return <SsSidePanelDashboard data={data}/>
    }
};

const SidePanelDashboard = memo(({type = 'SUPERSET'}: { type?: DashboardType }) => {
    const [dashboardId, setDashboardId] = useState<string>('');
    const [dashboard, setDashboard] = useState<DashboardEmbeddingResultEnvelopeEntity>();
    const [options, setOptions] = useState<DropDownOptionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const projectId = useTypedSelector(selectCurrentProjectId);
    const selectedRunId = useTypedSelector(selectSelectedRunId);
    const runs = useTypedSelector(selectProjectRuns);
    const {theme} = useTheme();
    const {t} = useTranslation();

    const isProjectSimulated = !!runs.length;

    useEffect(() => {
        if (isProjectSimulated) {
            const abortController = new AbortController();

            (async function () {
                setLoading(true);

                const dashboardsLst: DashboardTemplateEntity[] = (await dashboardApi.getDashboardTemplates({
                    projectId,
                    abortSignal: abortController.signal
                }))?.filter(({type: dashType}) => dashType === type);

                setLoading(false);

                if (dashboardsLst.length) {
                    const firstDashboard = dashboardsLst[0];
                    setOptions(getOptions(dashboardsLst));

          await loadDashboard({dashboardId: firstDashboard.id, projectId, selectedRunId});
        }

            })();
        }
    }, [isProjectSimulated, selectedRunId, projectId])

  const loadDashboard = useCallback(async ({projectId, dashboardId, selectedRunId}: {
    projectId: string;
    dashboardId: string;
    selectedRunId: string;
  }) => {
    const abortController = new AbortController();

        setLoading(true);
        setDashboardId(dashboardId);

    const resp = await dashboardApi.getEmbeddedDashboard({
      projectId,
      dashboardId,
      runId: selectedRunId,
      abortSignal: abortController.signal
    });

        if (!isErrorResponse(resp)) {
            setDashboard(resp);
        }

        setLoading(false);
    }, [])

  const getOptions = (dashboardsLst: DashboardTemplateEntity[]): DropDownOptionType[] => {
    return dashboardsLst.map(({name, id}) => ({
      title: name,
      onClick: () => loadDashboard({dashboardId: id, projectId, selectedRunId})
    }))
  }

    return (
        <div className="sidebar-dashboards">
            {
                isProjectSimulated
                    ? <>
                        <div className="sidebar-dashboards__controls">
                            {
                                options.length
                                    ? <>
                                        <DropDown
                                            options={options}
                                            // optionsWidth={170}
                                            titleColorLight={themeColors.navyBlue}
                                            titleColorDark={themeColors.grey3}
                                            optionsAlign={OptionsAlign.LEFT}
                                            optionColorLight={themeColors.navyBlue}
                                            optionColorDark={themeColors.white}
                                            iconColorLight={themeColors.navyBlue}
                                            iconColorDark={themeColors.grey3}
                                        />
                                        <Link
                                            to={`./dashboard/${selectedRunId}/${dashboardId}/sdashboard`}
                                            target='_blank'
                                            style={{
                                                color: theme === 'light' ? themeColors.bluePurple : themeColors.white
                                            }}
                                        >
                                            {t('common.open-in-new-tab')}
                                        </Link>
                                    </>
                                    : null
                            }
                        </div>
                        <div className="sidebar-dashboards__iframe">
                            <LoadingBackdrop isVisible={loading} transparent>
                                {
                                    dashboard
                                        ? getDashboardByType(dashboard)
                                        : null
                                }
                            </LoadingBackdrop>
                        </div>
                    </>
                    : <p>{t('simulation.simulate-to-see-dashboard-msg')}</p>
            }
        </div>
    )
})

export default SidePanelDashboard;