import React, {createContext, useContext, useEffect, useState} from 'react';
import {DataById, MapFiltersType} from "../redux/map/types";
import {usePresetsContext} from "./presetsContext";


const MapFiltersContext = createContext({
    loading: false,
    setLoading: (loading: boolean) => {
        return;
    },
    enabledFilters: {},
    setEnabledFilters: (filters: MapFiltersType) => {
        return
    },
    filters: [],
    setFilters: (filters: DataById) => {
        return
    },
    mapFilter: [] as any,
    setMapFilter: (arr: any[] | null) => {
        return;
    }
});

const useMapFiltersContext = () => {
    return useContext(MapFiltersContext);
};

type ProviderProps = {
    children: React.ReactNode,
    loadEnabledFilters?: (presetId: string) => Promise<{ filters, enabledFilters }>,
    defaultFilters?: MapFiltersType,
}

const MapFiltersProvider = ({children, loadEnabledFilters, defaultFilters}: ProviderProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const {selectedPresetId} = usePresetsContext();
    const [enabledFilters, setEnabledFilters] = useState<any>(defaultFilters);
    const [filters, setFilters] = useState<any>([]);
    const [mapFilter, setMapFilter] = useState<any>([]);

    useEffect(() => {
        if (selectedPresetId && loadEnabledFilters) {
            (async function () {
                setLoading(true);
                const {enabledFilters, filters} = await loadEnabledFilters(selectedPresetId);
                setEnabledFilters(enabledFilters);
                setFilters(filters);
                setLoading(false);
            }());
        }
    }, [selectedPresetId, loadEnabledFilters]);

    const value = {
        loading,
        setLoading,
        enabledFilters,
        setEnabledFilters,
        filters,
        setFilters,
        mapFilter,
        setMapFilter
    }

    return (
        <MapFiltersContext.Provider value={value}>
            {children}
        </MapFiltersContext.Provider>
    );
};

export {MapFiltersProvider, useMapFiltersContext};