import {useEffect, useRef} from "react";
import {embedDashboard} from "@superset-ui/embedded-sdk";
import './side-panel-dashboards.scss';
import {
    DashboardEmbeddingResultEnvelopeEntity
} from "../../api/entities/local/replancity_DashboardEmbeddingResultEnvelope";


type Props = {
    data: DashboardEmbeddingResultEnvelopeEntity;
}

const SsSidePanelDashboard = ({data}: Props) => {
    const ref = useRef();

    useEffect(() => {
        if (data) {
            (async function () {
                const {dashboardExternalID, dashboardSideBarID, link, secretCode, sideBarSecretCode} = data;

                await embedDashboard({
                    id: (dashboardExternalID ?? dashboardSideBarID)!,
                    supersetDomain: link,
                    mountPoint: ref.current!,
                    fetchGuestToken: async () => secretCode ?? sideBarSecretCode,
                    dashboardUiConfig: {
                        hideTitle: true,
                        filters: {
                            visible: false,
                            expanded: false
                        }
                    },
                    // debug: true
                });
            }());
        }
    }, [data]);


    return (
        <div
            className="sup-set-dashboard"
            ref={ref as any}
        ></div>
    )
}

export default SsSidePanelDashboard;