import {useCallback, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../redux/Hooks/storeSelectors";
import {ViewPortCoordinates} from "../../../../api/entities/local/Borders";
import {
    selectRoadRouteCheckerMarkersCoordinates,
    selectViewPortCoordinates
} from "../../../../redux/selectors/selectors";
import {
    ROAD_ROUTE_CHECKER_MARKER_NAME,
    setRoadRouteCheckPoint
} from "../../../../redux/road-route-check/road-route-check-reducer";


type Props = {
    [key in ROAD_ROUTE_CHECKER_MARKER_NAME]: string;
}

export const SECOND_MARKER_OFFSET = 0.008

export const useMapMarkersCoordinates = ({fromPoint, toPoint}: Props) => {
    const viewPortCoordinates = useTypedSelector<ViewPortCoordinates>(selectViewPortCoordinates);
    const markersCoordinates = useTypedSelector(selectRoadRouteCheckerMarkersCoordinates);
    const isMounted = useRef<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const {minLat, minLon} = viewPortCoordinates;
        if (!isMounted.current && minLat && minLon) {
            const {minLat, maxLat, minLon, maxLon} = viewPortCoordinates;
            const centerLat = (maxLat + minLat) / 2;
            const centerLon = (maxLon + minLon) / 2;

            dispatch(setRoadRouteCheckPoint({
                fromPoint: {
                    lng: centerLon,
                    lat: centerLat
                },
                toPoint: {
                    lng: centerLon + SECOND_MARKER_OFFSET,
                    lat: centerLat + SECOND_MARKER_OFFSET
                }
            }));
            isMounted.current = true;
        }
    }, [fromPoint, toPoint, viewPortCoordinates]);

    const setMarkerCoordinates = useCallback((markerId: keyof typeof ROAD_ROUTE_CHECKER_MARKER_NAME, lngLat: any) => {
        dispatch(setRoadRouteCheckPoint({[markerId]: {...lngLat}}));
    }, [])

    return {
        markersCoordinates,
        setMarkerCoordinates
    };
}